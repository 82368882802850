/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";
@import "./assets/scss/helpers.scss";

.router-wrapper {
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: calc(100% - 47px);
    perspective: 1200px;
    transform-style: preserve-3d;
}

:host {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #60d7a9;
}

.table {
    &.border-top-0 {
        th {
            border-top: none !important;
        }
    }
}

.dropdown-no-anchor {
    .dropdown-toggle::after {
        display: none;
    }
}

.ng-select {
    .ng-select-container,
    &.ng-select-single .ng-select-container {
        min-height: 38px;
    }

    &.ng-select-opened>.ng-select-container,
    .ng-select-container,
    .ng-dropdown-panel {
        border-color: #ced4da !important;
    }
}